

.page{
  height: 100vh;
  width: 100%;
  display: flex;
  padding: 20px;
  margin: 0;
}

.bg1{
  background-color: var(--color-palette-3);
}

.bg2{
  background-color: var(--color-palette-0);
}

.no-link-decor{
  text-decoration: none;
}