.flex-grid-col{
  /* Create a  */
  display: flex;
  flex-direction: row;
}

.flex-grid-row{
  /* Create a  */
  display: flex;
  flex-direction: column;
}

.flex-grid-item{
  /* Create a  */
  display: flex;
}

.flex-row-left {
  display: flex;
  justify-content: left;
  align-items: center;
}

.flex-col-left {
  display: flex;
  justify-content: center;
  align-items: left;
}

.flex-row-right {
  display: flex;
  justify-content: right;
  align-items: center;
}

.flex-col-right {
  display: flex;
  justify-content: center;
  align-items: right;
}

.height-80{
  max-height: 80vh;

}

.flex-center {
  justify-content: center;
  align-items: center;
}

.stick-right{
  margin-left: auto;
}

.flex-right{
  justify-content: flex-end;
}

.flex-col-right{
  align-items: end;
}

.flex-wrap{
  flex-wrap: wrap;
}