.vertical-tabs {
  display: flex;
}

.tabs {
  width: 33%;
  background-color: var(--color-palette-white);
  /* border: 1px solid var(--color-palette-3); */
}

.tab {
  padding: 10px;
  cursor: pointer;
  /* border: 1px solid var(--color-palette-3); */
  border-bottom: 1px solid var(--color-palette-3);
  border-left: 1px solid var(--color-palette-3);
  border-top: none;
  margin-bottom: 5px;
}

.tab:hover {
  padding: 10px;
  cursor: pointer;
  border-right: 20px solid var(--color-palette-3);
}

.tab.active {
  /* background-color: var(--color-palette-3); */
  border-right: 20px solid var(--color-palette-3);
}

.content {
  flex: 1;
  padding: 10px;
  border: 1px solid var(--color-palette-3);
  border-left: 10px solid var(--color-palette-3);
  margin-left: 20px;

}

.tab-border {
  margin: 5px;
  /* border: 1px solid var(--color-palette-black); */
  padding: 20px;
}