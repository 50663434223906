.full-page-layout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  margin: 0;
  padding: 0;
}

.full-page-layout-flex-start{
  justify-content: start;
  align-items: center;
  padding-top: 20px;
}