.block-card{
  font-family: 'Roboto', sans-serif;
  font-size: calc(var(--base-width-size)*0.7);
  font-weight: 100;
  border: 1px solid;
  border-radius: 0;
  background-color: transparent;
  color: var(--color-palette-black);
  padding: 16px;
  min-height: var(--base-width-size);
  min-width: 50%;
  width: 55%;
  max-width: 66%;
  display: flex;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  margin: 5px;
  /* max-height: var(--base-height-size); */
}

.block-card-medium-shadow{
  font-family: 'Roboto', sans-serif;
  font-size: calc(var(--base-width-size)*0.5);
  font-weight: 100;
  border: 1px solid;
  border-radius: 0;
  background-color: transparent;
  color: var(--color-palette-black);
  padding: 16px;
  min-height: calc(var(--base-width-size)*0.7);
  min-width: 40%;
  width: 50%;
  max-width: 60%;
  display: flex;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  margin: 5px;
  margin-bottom: 25px;
  box-shadow: rgba(239, 71, 111, 0.4) 5px 5px, rgba(239, 71, 111, 0.3) 10px 10px, rgba(239, 71, 111, 0.2) 15px 15px, rgba(239, 71, 111, 0.1) 20px 20px, rgba(239, 71, 111, 0.05) 25px 25px;  
}

.block-card-right {
  justify-content: right;
  text-align: right;
}

.block-card-right:hover{
  width: 60%;
  max-width: 70%;
}

.block-card-left {
  justify-content: left;
  text-align: left;
}
.block-card-left:hover{
  width: 60%;
  max-width: 70%;
}

.border-right-5 {
  border-right-width: calc(var(--base-unit-size)*50);
  border-right-color: var(--color-palette-3);
}

.border-left-5 {
  border-left-width: calc(var(--base-unit-size)*50);
  border-left-color: var(--color-palette-3);
}