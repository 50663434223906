.dynamic-image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 10px;
}

.image-container {
  
  width: 30%;
  margin: 5px;
}

.image {
  width: 100%;
  max-height: 300px;
  /* height: 100%; */
  object-fit: cover;
  margin: 5px;
  
}