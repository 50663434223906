.text-title {
  font-family: 'Roboto', sans-serif;
  font-size: calc(var(--base-width-size)*1.2);
  font-weight: 100;
  text-transform: uppercase;
}

.text-h2{
  font-family: 'Roboto', sans-serif;
  font-size: calc(var(--base-width-size)*0.7);
  font-weight: 100;
  text-transform: uppercase;
}

.text-h4{
  font-family: 'Poppins', sans-serif;
  font-size: calc(var(--base-width-size)*0.3);
  font-weight: 200;
  text-transform: uppercase;
}

.text-normal{
  font-family: 'Poppins', sans-serif;
  font-size: calc(var(--base-width-size)*0.3);
  font-weight: 400;
}

.text-center {
  text-align: center;
}

.text-font-size-1{
  font-size: calc(var(--base-width-size));
}

.camelcase{
  text-transform: none;
}

