.line-1{
  max-height: 1px;
  height: 2px;
  width: 100%;
  background-color: var(--color-palette-3);
}

.v-line-1{
  max-width: 1px;
  width: 2px;
  height: 100%;
  background-color: var(--color-palette-3);
}

.v-line-margin-1px{
  margin-left: 1px;
}

.line-margin-1px{
  margin-bottom: 1px;
}